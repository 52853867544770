import React, { useEffect, useRef, useState } from 'react'
import {
  Button,
  Card,
  CardContent,
  IconButton,
  MobileStepper,
  Typography,
  useTheme,
} from '@material-ui/core'
import {
  GetApp,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Translate,
  VolumeOff,
} from '@material-ui/icons'
import SwipeableViews from 'react-swipeable-views'
import styled from 'styled-components'

import Image1 from './images/panel-1.jpg'
import Image2 from './images/panel-2.jpg'
import Image3 from './images/panel-3.jpg'

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const FixedButton = styled(IconButton)<{ verticalPosition: 'right' | 'left' }>`
  position: fixed;
  top: 1rem;
  ${({ verticalPosition }) => verticalPosition}: 1rem;
  z-index: 1;
`

const ImageContainer = styled.div`
  height: 80vw;
`

const Image = styled.img<{ fadeOut?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vw;
  background-color: white;

  opacity: ${({ fadeOut }) => (fadeOut ? 0 : 1)};
  transition: opacity 1s ease-in-out;
`

const CardContentCondensed = styled(CardContent)`
  padding: 0.8em !important;

  b {
    color: black;
  }

  p {
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 0.8em;
    }
  }
`

const HideableButton = styled(Button)<{ hide?: boolean }>`
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  transition: opacity 0.5s ease-in-out;
`

const DownloadButton = styled(Button)`
  margin: 0 1rem 1rem;
  width: calc(100% - 2rem);
`

const StoryCard: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return (
    <Card raised>
      <CardContentCondensed>
        <Typography
          variant='body2'
          color='textSecondary'
          component='div'
          style={{ fontSize: '100%' }}
        >
          {children}
        </Typography>
      </CardContentCondensed>
    </Card>
  )
}

const imageSources = [Image1, Image2, Image3]
const STEPS = 7

//  width: 'calc(100% - 3rem)'
function App() {
  // todo: warning on ios

  const [index, setIndex] = useState<number>(0)
  const theme = useTheme()
  const downloadButton = useRef<HTMLAnchorElement>()

  useEffect(() => {
    if (index === STEPS - 1) {
      downloadButton.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [index, downloadButton])

  const handleNext = () => {
    setIndex((previousIndex) => previousIndex + 1)
  }

  const handleBack = () => {
    setIndex((previousIndex) => previousIndex - 1)
  }

  return (
    <AppContainer>
      <FixedButton verticalPosition='right' aria-label='Sprache wechseln'>
        <Translate />
      </FixedButton>
      <FixedButton verticalPosition='left' aria-label='TO DO'>
        <VolumeOff />
      </FixedButton>
      <ImageContainer>
        <Image key={index} src={imageSources[index]} />
        {index > 0 && (
          <Image key={index - 1} src={imageSources[index - 1]} fadeOut />
        )}
      </ImageContainer>
      <SwipeableViews
        style={{ padding: '0 2rem' }}
        slideStyle={{
          padding: '1rem 0.7rem 1.1rem',
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
        index={index}
        onChangeIndex={(newIndex) => setIndex(newIndex)}
      >
        <StoryCard>
          <b>Wir sind Florian und Clemens</b> aus Deutschland. Clemens ist
          Programmierer und Florian ist Journalist. Wir wollen dokumentieren,
          was dir während der Games passiert und wie die kroatische Polizei mit
          dir umgeht! Hilfst du uns dabei?
        </StoryCard>
        <StoryCard>
          <b>Asyl ist ein Grundrecht.</b> Sobald du es über die europäische
          Grenze nach Kroatien geschafft hast, hast du das Recht einen
          Asylantrag zu stellen. Schickt dich die Polizei ohne die Chance einen
          Asylantrag zu stellen nach Bosnien zurück oder verprügelt dich sogar,
          ist das illegal. Wir wollen dieses Vorgehen dokumentieren.
        </StoryCard>
        <StoryCard>
          Dafür brauchen wir <b>deine Hilfe</b>! Die{' '}
          <b>Standortdaten deines Smartphones</b> können uns helfen, das
          Vorgehen der kroatischen Polizei festzuhalten. Wenn du uns deine Daten
          übermittelst können wir Beweise sammeln, falls du illegal aus der EU
          abgeschoben wirst.
        </StoryCard>
        <StoryCard>
          Clemens hat eine <b>App</b> programmiert, die uns automatisch deine
          Standortdaten schickt. Wenn dein Handy im Flugmodus ist, werden die
          Daten gespeichert. Sobald es wieder eine Internetverbindung gibt
          werden sie an uns gesendet. Du musst dich um nichts kümmern. Die App
          ist als Gebetsapp getarnt, um unauffällig zu sein.
        </StoryCard>
        <StoryCard>
          <b>Wir geben deine Daten nicht weiter!</b> Auch nicht an die
          kroatische Polizei, FRONTEX oder die EU. Die Standortdaten werden über
          die App ausschließlich an Clemens geschickt und können dir nicht
          zugeordnet werden.
        </StoryCard>
        <StoryCard>
          Um die App zu installieren, musst du vielleicht eine{' '}
          <b>Einstellung ändern</b>. Nach dem Download erscheint manchmal eine
          Warnung. Öffne von dort die Systemeinstellungen und erlaube die
          Installation. Dann gehst du zurück und kannst die App installieren.
        </StoryCard>
        <StoryCard>
          <p>
            <b>Lade jetzt die App herunter und installiere sie!</b>
          </p>
          <p>
            Du musst nichts weiter machen. Verwende dein Handy wie gewohnt.
            Erzähle gerne deinen Freund:innen von der App: https://
            <b>howmany.games</b>
          </p>
          <p>Vielen Dank für deine Hilfe!</p>
        </StoryCard>
      </SwipeableViews>
      <MobileStepper
        variant='dots'
        steps={STEPS}
        position='static'
        activeStep={index}
        style={{
          position: 'relative',
          bottom: '0.8rem',
          backgroundColor: 'transparent',
        }}
        nextButton={
          <HideableButton
            size='small'
            onClick={handleNext}
            disabled={index === STEPS - 1}
            hide={index === STEPS - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </HideableButton>
        }
        backButton={
          <HideableButton
            size='small'
            onClick={handleBack}
            disabled={index === 0}
            hide={index === 0}
          >
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </HideableButton>
        }
      />
      <DownloadButton
        variant='contained'
        color={index === STEPS - 1 ? 'primary' : undefined}
        size='large'
        endIcon={<GetApp />}
        href={'https://weg.neopostmodern.com/release.apk'}
        ref={downloadButton as any}
      >
        Download (2MB)
      </DownloadButton>
    </AppContainer>
  )
}

export default App
